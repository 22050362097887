import { NexusHomepageTabs } from '@domains/home/components/NexusHomepageTabs/NexusHomepageTabs';
import { PopularLocationSliderTile } from '@domains/home/components/NexusPopularLocationsSection/components/PopularLocationSliderTile';
import { useGetPropertiesForSellAndForRentFromPopularLocations } from '@domains/home/hooks/useGetPropertiesForSellAndForRentFromPopularLocations';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { type FC, type JSX, useMemo, useRef } from 'react';

import { StyledNexusSectionWrapper } from './NexusPopularLocationsSection.theme';

export const NexusPopularLocationsSection: FC = () => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const {
        propertiesForSell: propertiesForSellFromPopularLocations,
        propertiesForRent: propertiesForRentFromPopularLocations,
        propertiesFromPrimaryMarket: propertiesFromPrimaryMarketFromPopularLocations,
    } = useGetPropertiesForSellAndForRentFromPopularLocations();
    const nexusPopularLocationsRef = useRef<HTMLDivElement | null>(null);

    useTrackImpression(nexusPopularLocationsRef, () => {
        trackEvent('seo_links_impression', { touch_point_button: 'popular_locations' });
    });

    const popularLocationsTransactions = [
        propertiesForSellFromPopularLocations,
        propertiesForRentFromPopularLocations,
        propertiesFromPrimaryMarketFromPopularLocations,
    ];

    const tabs = useMemo(
        () => [
            {
                name: t('frontend.homepage.popular-locations.for-sale-label'),
                touchPointButton: 'popular_locations_sale',
            },
            {
                name: t('frontend.homepage.popular-locations.for-rent-label'),
                touchPointButton: 'popular_locations_rent',
            },
            {
                name: t('frontend.homepage.popular-locations.primary-market-label'),
                touchPointButton: 'popular_locations_primary_market',
            },
        ],
        [t],
    );

    return (
        <StyledNexusSectionWrapper
            headline={t('frontend.homepage.popular-locations.headline')}
            ref={nexusPopularLocationsRef}
            isScrollableHorizontally
        >
            <NexusHomepageTabs tabs={tabs}>
                {popularLocationsTransactions.map((linksTile) => (
                    <Slider
                        key={linksTile[0].id}
                        items={linksTile}
                        buttonTopPosition={-68}
                        trackingTouchPointButton="popular_locations"
                        shouldUseEdgePaddings
                        renderItem={({ imageUrl, header, links, id, cityLandingLocation }): JSX.Element => (
                            <PopularLocationSliderTile
                                id={id}
                                title={header}
                                links={links}
                                image={imageUrl}
                                cityLandingLocation={cityLandingLocation}
                            />
                        )}
                    />
                ))}
            </NexusHomepageTabs>
        </StyledNexusSectionWrapper>
    );
};

import type { SchemaMarkupConfig } from '@config/schemaMarkup/type';
import { SITE_CONFIG } from '@config/siteConfig';

const BASE_URL = `https://${SITE_CONFIG.metadata.siteURL}`;

export const SCHEMA_MARKUP_CONFIG: SchemaMarkupConfig = {
    webPage: {
        '@type': 'WebPage',
        url: BASE_URL,
        isPartOf: {
            '@type': 'WebSite',
            name: 'Otodom',
        },
        about: {
            '@type': 'Organization',
            foundingDate: '2006',
            logo: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.logo}`,
            brand: 'Grupa OLX sp. z o. o.',
            alternateName: [
                'oto dom',
                'otodom.pl',
                'otodom olx',
                'otodom warszawa',
                'otodom wrocław',
                'otodom pl',
                'otodon',
                'www.otodom.pl',
                'otodo',
                'otodom domy',
                'otodom mieszkania',
            ],
            sameAs: [
                'https://pl.wikipedia.org/wiki/Otodom',
                'https://www.facebook.com/Otodom/',
                'https://www.instagram.com/otodom/',
                'https://twitter.com/otodom',
                'https://www.youtube.com/@otodom',
                'https://www.linkedin.com/company/otodom',
                'https://play.google.com/store/apps/details',
            ],
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'Poznań',
                postalCode: '61-872',
                addressRegion: 'Poznań/Pojezierze Wielkopolskie',
                streetAddress: 'ul. Królowej Jadwigi 43',
                addressCountry: {
                    '@type': 'Country',
                    name: 'Polska',
                },
            },
            contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+48 61 880 3222',
                areaServed: 'Polska',
                contactType: 'customer service',
                availableLanguage: 'Polski',
            },
        },
    },
    searchAction: {
        '@type': 'WebSite',
        url: BASE_URL,
        potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name=search_term_string',
        },
    },
    product: {
        '@type': 'Product',
        additionalType: 'RealEstateListing',
        url: BASE_URL,
        adUrlPrefix: '/[lang]/oferta/',
        addressCountry: {
            '@type': 'Country',
            name: 'Polska',
        },
    },
};

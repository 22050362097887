import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import type { CSSProperties } from 'react';

export const SubHeadline = styled(P3)`
    display: none;

    @media ${theme.mediaQueries.smMin} {
        display: block;
        margin-bottom: ${theme.space[24]};
        color: ${theme.colors['text-global-secondary']};
    }
`;

export const ListSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${theme.space[16]};

    @media ${theme.mediaQueries.smMin} {
        flex-direction: row;
    }
`;

export const Description = styled(P3)`
    display: none;
    max-width: 334px;
    margin: ${theme.space[8]};
    color: ${theme.colors['text-global-secondary']};

    @media ${theme.mediaQueries.smMin} {
        display: block;
    }
`;

export const SubSectionWrapper = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: ${theme.space[16]} ${theme.space[24]};
    background: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    gap: ${theme.space[16]};

    @media ${theme.mediaQueries.smMin} {
        flex-direction: column;
        gap: 0;
        padding: ${theme.space[32]} ${theme.space[16]};
    }
`;

export const Icon = styled.svg<{ imagePath: string }>`
    display: block;
    width: 48px;
    height: 48px;
    background-image: ${({ imagePath }): CSSProperties['backgroundImage'] => `url(${imagePath})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;

    @media ${theme.mediaQueries.smMin} {
        width: 128px;
        height: 128px;
    }
`;

import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

const BAXTER_SLOT_MIN_HEIGHT = '170px';

export const StyledNexusSectionWrapper = styled(NexusSectionWrapper)`
    @media ${theme.mediaQueries.lgMin} {
        min-height: ${BAXTER_SLOT_MIN_HEIGHT};
    }
`;

export const LocalLinkContainer = styled.div`
    padding: 0 ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }
`;

import type { FaqPageSchemaMarkup, FaqSchemaItemData } from '@config/schemaMarkup/type';

export const generateFaqPageSchemaMarkup = (faqSchemaItems: FaqSchemaItemData[]): FaqPageSchemaMarkup | undefined => {
    if (faqSchemaItems.length === 0) return;

    return {
        '@type': 'FAQPage',
        mainEntity: faqSchemaItems.map(({ question, answer }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: answer,
            },
        })),
    };
};

import { CURRENT_SITE_CODE } from '@config/siteCode';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { P2 } from '@nexus/lib-react/dist/core/Typography';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import type { FC } from 'react';
import { useRef } from 'react';

import { Description, Icon, ListSection, SubHeadline, SubSectionWrapper } from './NexusPrimaryMarketSection.theme';

interface TrackingData {
    eventName: string;
    categoryName?: string;
}

const TRACKING_DATA = {
    touch_point_button: 'primary_market_re',
};

interface Props {
    availableSubSection: ('flat' | 'house' | 'commercial')[];
}

export const NexusPrimaryMarketSection: FC<Props> = ({ availableSubSection }) => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const containerRef = useRef(null);

    useTrackImpression(containerRef, () => {
        trackEvent('seo_links_impression', TRACKING_DATA);
    });

    const subSections = [
        {
            name: 'flat' as const,
            title: t('frontend.homepage.primary-market-section.flat-title'),
            description: t('frontend.homepage.primary-market-section.flat-description'),
            icon: `${staticAssetsPrefix}/images/homepage/primaryMarketSection/${CURRENT_SITE_CODE}/apartment.svg`,
            link: buildSearchUrl({
                lang,
                transaction: TRANSACTION.sell,
                estate: ESTATE.flat,
                isInternalRoute: false,
                location: URL_LOCATION_FILLER.allLocations.label,
                market: 'PRIMARY',
            }),
            buttonTitle: t('frontend.homepage.primary-market-section.flats-button-title'),
            trackingData: {
                eventName: 'seo_link_click',
                categoryName: 'flats',
            },
        },
        {
            name: 'house' as const,
            title: t('frontend.homepage.primary-market-section.house-title'),
            description: t('frontend.homepage.primary-market-section.house-description'),
            icon: `${staticAssetsPrefix}/images/homepage/primaryMarketSection/${CURRENT_SITE_CODE}/house.svg`,
            link: buildSearchUrl({
                lang,
                transaction: TRANSACTION.sell,
                estate: ESTATE.house,
                isInternalRoute: false,
                location: URL_LOCATION_FILLER.allLocations.label,
                market: 'PRIMARY',
            }),
            buttonTitle: t('frontend.homepage.primary-market-section.house-button-title'),
            trackingData: {
                eventName: 'posting_form_click',
                categoryName: 'houses',
            },
        },
        {
            name: 'commercial' as const,
            title: t('frontend.homepage.primary-market-section.commercial-title'),
            description: t('frontend.homepage.primary-market-section.commercial-description'),
            icon: `${staticAssetsPrefix}/images/homepage/primaryMarketSection/${CURRENT_SITE_CODE}/office.svg`,
            link: buildSearchUrl({
                lang,
                transaction: TRANSACTION.sell,
                estate: ESTATE.commercialProperty,
                isInternalRoute: false,
                location: URL_LOCATION_FILLER.allLocations.label,
                market: 'PRIMARY',
            }),
            buttonTitle: t('frontend.homepage.primary-market-section.commercial-button-title'),
            trackingData: {
                eventName: 'seo_link_click',
                categoryName: 'commercial properties',
            },
        },
    ];

    const trackClick = ({ eventName, categoryName }: TrackingData): void => {
        trackEvent(eventName, {
            ...TRACKING_DATA,
            cat_l1_name: categoryName,
        });
    };

    return (
        <NexusSectionWrapper headline={t('frontend.homepage.primary-market-section.main-headline')} ref={containerRef}>
            <SubHeadline>{t('frontend.homepage.primary-market-section.description')}</SubHeadline>
            <ListSection>
                {subSections
                    .filter((subSection) => availableSubSection.includes(subSection.name))
                    .map(({ buttonTitle, title, icon, description, link, trackingData }) => (
                        <LocalLink href={link} passHref key={buttonTitle}>
                            <SubSectionWrapper
                                onClick={() => trackClick(trackingData)}
                                data-cy="primary-market-seo-link"
                            >
                                <Icon imagePath={icon} />
                                <P2 variant="bold">{title}</P2>
                                <Description>{description}</Description>
                            </SubSectionWrapper>
                        </LocalLink>
                    ))}
            </ListSection>
        </NexusSectionWrapper>
    );
};

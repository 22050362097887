import type { JSX } from 'react';

import {
    BottomContainer,
    ImagePlaceholder,
    LabelWrapperPlaceholder,
    TextPlaceholder,
    TitlePlaceholder,
} from './LatestArticlesSliderTile.skeleton.theme';
import { CalendarIcon, Tile } from './LatestArticlesSliderTile.theme';

export const LatestArticlesSliderTileSkeleton = (): JSX.Element => {
    return (
        <Tile as="div">
            <ImagePlaceholder />
            <BottomContainer>
                <LabelWrapperPlaceholder>
                    <CalendarIcon />
                    <TextPlaceholder width="40%" />
                </LabelWrapperPlaceholder>
                <TitlePlaceholder as="span">
                    <TextPlaceholder width="80%" />
                    <TextPlaceholder />
                    <TextPlaceholder width="50%" />
                </TitlePlaceholder>
            </BottomContainer>
        </Tile>
    );
};

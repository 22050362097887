import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledNexusSectionWrapper = styled(NexusSectionWrapper)`
    padding-bottom: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        padding-bottom: ${theme.space[40]};
    }
`;

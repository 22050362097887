import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const StyledNexusSectionWrapper = styled(NexusSectionWrapper)`
    @media ${theme.mediaQueries.lgMin} {
        padding-top: ${theme.space[40]};
    }
`;

export const LinkLabel = styled(P2)`
    display: inline-block;
    margin-top: ${theme.space[24]};
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.space[16]};
    font-style: normal;
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.lineHeights[20]};

    @media ${theme.mediaQueries.xlMin} {
        display: none;
    }
`;

export const SliderWrapper = styled.div`
    @media ${theme.mediaQueries.lgMin} {
        margin-top: ${theme.space[32]};
    }
`;

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Segment, SegmentedControl } from '@nexus/lib-react/dist/core/SegmentedControl';
import type { CSSProperties } from 'react';

export const TabsWrapper = styled.div`
    display: inline-block;
    max-width: 100%;
    margin: 0 0 ${theme.space['24']} 0;
    padding: 0 ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }
`;

export const TabContent = styled.div<{ isVisible: boolean }>`
    display: ${({ isVisible }): CSSProperties['display'] => (isVisible ? 'block' : 'none')};
`;

export const StyledSegmentedControl = styled(SegmentedControl)`
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledSegment = styled(Segment)`
    white-space: nowrap;
`;

import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { LabelWrapper, Title } from './LatestArticlesSliderTile.theme';

export const BottomContainer = styled.span`
    display: block;
    padding: ${theme.space['16']};
`;

export const ImagePlaceholder = styled(Skeleton)`
    display: block !important;
    width: 100%;
    height: 160px;
`;

export const TextPlaceholder = styled(Skeleton)`
    height: 14px;
    margin: ${theme.space['2']} 0;
`;

export const TitlePlaceholder = styled(Title)`
    display: flex;
    flex-direction: column;
`;

export const LabelWrapperPlaceholder = styled(LabelWrapper)`
    > span {
        width: 80%;
    }
`;

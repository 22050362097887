import { HOME_CONFIG } from '@config/home/homeConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { LatestArticlesSliderTileSkeleton } from '@domains/cityLanding/components/LatestArticlesSection/components/LatestArticlesSliderTile/LatestArticlesSliderTile.skeleton';
import { HomePageHead } from '@domains/home/components/HomePageHead/HomePageHead';
import { NexusBrandingSection } from '@domains/home/components/NexusBrandingSection/NexusBrandingSection';
import { NexusFaqSection } from '@domains/home/components/NexusFaqSection/NexusFaqSection';
import { NexusPopularLocationsSection } from '@domains/home/components/NexusPopularLocationsSection/NexusPopularLocationsSection';
import { NexusPrimaryMarketSection } from '@domains/home/components/NexusPrimaryMarketSection/NexusPrimaryMarketSection';
import NexusPromotedOffersSection from '@domains/home/components/NexusPromotedOffersSection/NexusPromotedOffersSection';
import { NexusPropertiesForSellAndRentSection } from '@domains/home/components/NexusPropertiesForSellAndRentSection/NexusPropertiesForSellAndRentSection';
import { NexusUsabilitySection } from '@domains/home/components/NexusUsabilitySection/NexusUsabilitySection';
import { ServicesSection } from '@domains/home/components/ServicesSection/ServicesSection';
import type { HomepageAd } from '@domains/home/types/homepageAd';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterSlots } from '@domains/shared/components/Advertising/getAdverts';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import Toaster from '@domains/shared/components/Toast/Toaster';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { FontProvider } from '@domains/shared/theme/FontProvider';
import { BaxterScripts } from '@lib/baxter/BaxterScripts';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { withGraphQLClient } from '@lib/graphql/withGraphQLClient';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { PageProviders } from '@lib/pages/contexts/PageProviders/PageProviders';
import type { PageCoreProps } from '@lib/pages/types';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SearchFormValues } from '@type/search/searchFormValues';
import { createSearchUrlWithParams } from '@widgets/search/buildSearchUrl';
import { HomeSearchSection } from '@widgets/search/HomeSearchSection';
import type { SearchUrlParams } from '@widgets/search/types/searchUrlParams';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { StyledNexusSectionWrapper } from './HomePage.theme';

export interface HomePageProps {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    searchFormValues: SearchFormValues;
    promotedOffers: HomepageAd[];
}

const { staticAssetsPrefix } = getPublicEnvConfig();

const LazyNexusArticlesSection = dynamic(
    () => import('@domains/home/components/NexusArticlesSection/NexusArticlesSection'),
    {
        loading: () => <LatestArticlesSliderTileSkeleton />,
        ssr: true,
    },
);

const HomePageComponent = ({
    fieldsMetadataExperimentsVariants,
    promotedOffers,
}: HomePageProps & PageCoreProps): JSX.Element => {
    const { images } = SITE_CONFIG;
    const {
        searchBoxFieldsLayout,
        searchBoxRows,
        searchSection,
        primaryMarketSection,
        faqSectionItems,
        servicesSection,
        nexusUsabilitySection,
        baxterAds,
    } = HOME_CONFIG;
    const [t] = useTranslations();
    const router = useRouter();
    const { lang } = useSiteSettings();
    useBaxterAdvertising();
    const searchSectionBackgroundImageUrl = `${staticAssetsPrefix}${images.homepageBackground}`;

    const onSearchSectionSubmit = useCallback(
        async (params: SearchUrlParams): Promise<void> => {
            const urlToRedirect = createSearchUrlWithParams({
                params,
                lang,
                viewType: 'listing',
                shouldSetDefaultSortingParams: false,
            });

            await router.push(urlToRedirect);
        },
        [lang, router],
    );

    useBaxterSlots({ target: 'homePage' });

    return (
        <FontProvider>
            <MainLayout hasStickyPageHeader={false} isMinimal>
                <BaxterScripts shouldRenderAds />
                <Toaster />
                <HomePageHead />
                <HomeSearchSection
                    fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                    title={t('frontend.homepage.search-section.header-primary')}
                    subtitle={t('frontend.homepage.search-section.header-secondary')}
                    backgroundImageUrl={searchSectionBackgroundImageUrl}
                    searchBoxRows={searchBoxRows}
                    searchBoxFieldsLayout={searchBoxFieldsLayout}
                    onSubmit={onSearchSectionSubmit}
                    isGradientBackground={searchSection?.isGradientBackground}
                />
                <NexusPromotedOffersSection promotedOffers={promotedOffers} />
                {baxterAds?.shouldShowbaxterHMiddle1 ? (
                    <StyledNexusSectionWrapper>
                        <AdvertSlot id="baxter-h-middle-1" />
                    </StyledNexusSectionWrapper>
                ) : null}
                <NexusPropertiesForSellAndRentSection />
                {primaryMarketSection ? (
                    <NexusPrimaryMarketSection availableSubSection={primaryMarketSection.availableSubSection} />
                ) : null}
                <NexusBrandingSection />
                {nexusUsabilitySection ? <NexusUsabilitySection usabilityData={nexusUsabilitySection} /> : null}
                {servicesSection ? <ServicesSection services={servicesSection} /> : null}
                <LazyNexusArticlesSection />
                {faqSectionItems ? <NexusFaqSection items={faqSectionItems} /> : null}
                <NexusPopularLocationsSection />
            </MainLayout>
        </FontProvider>
    );
};

const HOME_PAGE_DEFAULT_TRACKING_DATA = {
    touch_point_page: 'home',
} as const;

export const HomePageWithProviders = (props: HomePageProps & PageCoreProps & LaquesisPageProps): JSX.Element => {
    const { experiments, laquesisResult, searchFormValues } = props;

    return (
        <PageProviders
            defaultTrackingData={HOME_PAGE_DEFAULT_TRACKING_DATA}
            experiments={experiments}
            laquesisResult={laquesisResult}
            trackPageName="home"
        >
            <SearchFormValuesContext.Provider value={searchFormValues}>
                <HomePageComponent {...props} />
            </SearchFormValuesContext.Provider>
        </PageProviders>
    );
};

export const HomePage = withGraphQLClient({
    component: HomePageWithProviders,
});

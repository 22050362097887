import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import type { ForwardRefRenderFunction, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { HeadlineLink, HeadlineWrapper, NexusHeadline, NexusWrapper } from './NexusSectionWrapper.theme';

interface Props {
    headline?: string;
    className?: string;
    link?: {
        label: string;
        href: string;
        onClick: () => void;
    };
    isScrollableHorizontally?: boolean;
}

const NexusSectionElement: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<Props>> = (
    { headline, link, children, className, isScrollableHorizontally, ...rest },
    ref,
) => {
    return (
        <NexusWrapper
            as="section"
            innerRef={ref}
            className={className}
            isScrollableHorizontally={isScrollableHorizontally}
            {...rest}
        >
            {headline ? (
                <HeadlineWrapper isScrollableHorizontally={isScrollableHorizontally}>
                    <NexusHeadline>{headline}</NexusHeadline>
                    {link ? (
                        <LocalLink href={link.href} passHref>
                            <HeadlineLink onClick={link.onClick}>{link.label}</HeadlineLink>
                        </LocalLink>
                    ) : null}
                </HeadlineWrapper>
            ) : null}
            {children}
        </NexusWrapper>
    );
};

export const NexusSectionWrapper = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(NexusSectionElement);

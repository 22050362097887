import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Container } from '@nexus/lib-react/dist/core/Layout';
import { H2 } from '@nexus/lib-react/dist/core/Typography';
import type { CSSProperties } from 'react';

export const HeadlineWrapper = styled.div<{ isScrollableHorizontally?: boolean }>`
    display: flex;
    align-items: center;
    margin: 0 0 ${theme.space[16]};
    padding: ${({ isScrollableHorizontally }): CSSProperties['padding'] =>
        isScrollableHorizontally ? `0 ${theme.space[16]}` : '0'};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }

    @media ${theme.mediaQueries.lgMin} {
        margin: 0 0 ${theme.space[24]};
    }
`;

export const NexusHeadline = styled(H2)`
    display: block;
    margin: 0;
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeights[22]};

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.typography.h2.fontSize};
        line-height: ${theme.typography.h2.lineHeight};
    }
`;

export const NexusWrapper = styled(Container)<{ isScrollableHorizontally?: boolean }>`
    padding: ${({ isScrollableHorizontally }): CSSProperties['padding'] =>
        isScrollableHorizontally ? `${theme.space[32]} 0 0` : `${theme.space[32]} ${theme.space[16]} 0`};
    background-color: ${theme.colors['background-global-secondary']};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[32]} ${theme.space[24]};
    }
`;

export const HeadlineLink = styled.a`
    display: none;

    @media ${theme.mediaQueries.xlMin} {
        display: block;
        margin-left: ${theme.space[32]};
        color: ${theme.colors['text-global-secondary']};
        font-size: ${theme.space[16]};
        font-style: normal;
        font-weight: ${theme.fontWeights[400]};
        line-height: ${theme.lineHeights[20]};
    }
`;

import { LinkButton } from '@domains/shared/components/nexus/LinkButton/LinkButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2, P3 } from '@nexus/lib-react/dist/core/Typography';

export const TileWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    width: 296px;
    background-color: ${theme.colors['background-global-primary']};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
    padding: ${theme.space[16]};
`;

export const Headline = styled(P2)`
    margin: 0 0 ${theme.space[8]};
`;

export const Description = styled(P3)`
    margin: 0 0 ${theme.space[8]};
    color: ${theme.colors['text-global-secondary']};
`;

export const StyledLinkButton = styled(LinkButton)`
    margin: auto 0 0;
`;

export const StyledImage = styled.img`
    object-fit: cover;
`;

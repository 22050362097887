import { HOME_CONFIG } from '@config/home/homeConfig';
import { NexusWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper.theme';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { useRef } from 'react';

import {
    BrandingSectionHeadline,
    BrandingSectionParagraph,
    ContentWrapper,
    StyledImage,
    StyledLinkButton,
} from './NexusBrandingSection.theme';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const NexusBrandingSection = (): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const nexusBrandingSectionRef = useRef<HTMLDivElement | null>(null);
    const { nexusBrandingSection } = HOME_CONFIG;

    useTrackImpression(nexusBrandingSectionRef, () => {
        trackEvent('see_all_offers_impression', {
            touch_point_button: 'see_all_offers',
        });
    });

    const onClick = (): void => {
        trackEvent('see_all_offers', {
            touch_point_button: 'see_all_offers',
        });
    };

    return (
        <NexusWrapper as="section" innerRef={nexusBrandingSectionRef}>
            <ContentWrapper>
                <StyledImage
                    alt={t('frontend.homepage.branding-section.img-alt')}
                    src={`${staticAssetsPrefix}${nexusBrandingSection.image}`}
                />
                <div>
                    <BrandingSectionHeadline>
                        {t('frontend.homepage.branding-section.headline')}
                    </BrandingSectionHeadline>
                    <BrandingSectionParagraph>
                        {t('frontend.homepage.branding-section.paragraph')}
                    </BrandingSectionParagraph>
                    <LocalLink
                        href={buildSearchUrl({
                            lang,
                            estate: ESTATE.flat,
                            transaction: TRANSACTION.sell,
                            isInternalRoute: false,
                        })}
                        passHref
                    >
                        <StyledLinkButton variant="secondary" onClick={onClick}>
                            {t('frontend.homepage.branding-section.btn')}
                        </StyledLinkButton>
                    </LocalLink>
                </div>
            </ContentWrapper>
        </NexusWrapper>
    );
};

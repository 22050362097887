import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const TILE_WIDTH = 296;

const heightBasedOnElements = (elementsLength: number): string => (elementsLength > 3 ? '128px' : '72px');

export const Tile = styled.div`
    position: relative;
    width: ${TILE_WIDTH}px;
    padding: 0 0 74px;
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
    text-decoration: none;
`;

export const Image = styled.img`
    display: block;
`;

export const LinksContainer = styled.ul<{ isActive: boolean }>`
    display: ${({ isActive }): string => (isActive ? 'block' : 'none')};
    padding: 0;
    list-style: none;
`;

export const ListItem = styled.li`
    cursor: pointer;
    &:not(:first-of-type) {
        padding: ${theme.space['8']} ${theme.space['0']} ${theme.space['0']};
    }
    &:first-of-type {
        padding: ${theme.space['16']} ${theme.space['0']} ${theme.space['0']};
    }
`;

export const AccordionButton = styled(Button)<{ isActive: boolean }>`
    height: ${({ isActive }): string => (isActive ? 'auto' : '42px')};
`;

export const ContentWrapper = styled.div<{ isActive: boolean; linksLength: number }>`
    position: absolute;
    bottom: ${({ isActive }): string => (isActive ? '74px' : '0')};
    width: 100%;
    height: ${({ isActive, linksLength }): string => (isActive ? heightBasedOnElements(linksLength) : '74px')};
    padding: ${theme.space['16']};
    background-color: ${theme.colors['background-global-primary']};
`;

import { HOME_CONFIG } from '@config/home/homeConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { useGetSeoSchemaMarkup } from '@domains/home/hooks/useGetSeoSchemaMarkup';
import { getStringifySchemaMarkup } from '@domains/shared/helpers/schemaMarkup/getStringifySchemaMarkup';
import { useMobileRecognition } from '@domains/shared/hooks/useMobileRecognition/useMobileRecognition';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import Head from 'next/head';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

const addLanguageToCanonicalUrlIfNeeded = (canonicalURL: string, lang: Locale): string => {
    const { defaultLanguage } = SITE_CONFIG.metadata;

    return lang === defaultLanguage ? canonicalURL : `${canonicalURL}/${lang}`;
};

export const HomePageHead: FC = () => {
    const { isAppleDevice } = useMobileRecognition();
    const [isApple, setIsApple] = useState(false);
    const { lang } = useSiteSettings();
    const schemaMarkupData = useGetSeoSchemaMarkup();
    const stringifySchemaMarkup = getStringifySchemaMarkup(schemaMarkupData);
    const { pinterestVerifyCode, canonicalURL } = HOME_CONFIG;
    const [t] = useTranslations();

    useEffect(() => {
        if (!navigator) {
            return;
        }

        if (isAppleDevice(navigator.userAgent, navigator.platform)) {
            setIsApple(true);
        }
    }, [isAppleDevice]);

    return (
        <Head>
            <title>{t('frontend.homepage.meta.page-title')}</title>
            <link rel="canonical" href={addLanguageToCanonicalUrlIfNeeded(canonicalURL, lang)} key="link_canonical" />
            <meta property="og:title" content={t('frontend.homepage.meta.page-title')} key="meta_property_og:title" />
            <meta
                name="description"
                content={t('frontend.homepage.meta.page-description')}
                key="meta_name_description"
            />
            <meta
                property="og:description"
                content={t('frontend.homepage.meta.page-description')}
                key="meta_property_og:description"
            />

            {pinterestVerifyCode ? <meta name="p:domain_verify" content={pinterestVerifyCode} /> : null}

            {/**
             * 'maximum-scale' parameter works differently on different browsers. It allows pinch-zoom on safari but disables this functionality on android chrome.
             * To fix the issue with zooming in on search form on apple devices the meta tag its being added conditionally.
             * Thanks to this solution bug on safari is fixed and pinch zoom is still available on both systems.
             */}
            {isApple ? (
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
            ) : null}
            <meta name="robots" content="index, follow" key="meta_name_robots" />
            {stringifySchemaMarkup ? (
                <script
                    key="home-page-schema-markup"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: stringifySchemaMarkup }}
                />
            ) : null}
        </Head>
    );
};

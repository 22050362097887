import { useTracking } from '@lib/tracking/useTracking';
import type { FC, PropsWithChildren } from 'react';
import { Children, useCallback, useState } from 'react';

import { StyledSegment, StyledSegmentedControl, TabContent, TabsWrapper } from './NexusHomepageTabs.theme';

interface Tab {
    name: string;
    touchPointButton: string;
}

interface Props {
    tabs: Tab[];
}

export const NexusHomepageTabs: FC<PropsWithChildren<Props>> = ({ children, tabs }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { trackEvent } = useTracking();

    const handleSegmentChange = useCallback(
        (segmentName: string) => {
            const currentTab = tabs.find((tab) => tab.name === segmentName);
            if (currentTab) {
                trackEvent('switch_click', { touch_point_button: currentTab.touchPointButton });
            }
            const currentIndex = tabs.findIndex((tab) => tab.name === segmentName);
            setCurrentTabIndex(currentIndex);
        },
        [tabs, trackEvent],
    );

    return (
        <>
            <TabsWrapper>
                <StyledSegmentedControl
                    activeSegment={tabs[currentTabIndex].name}
                    size="small"
                    variant="light"
                    onSegmentChange={handleSegmentChange}
                >
                    {tabs.map((tab) => (
                        <StyledSegment name={tab.name} key={tab.name} />
                    ))}
                </StyledSegmentedControl>
            </TabsWrapper>
            {Children.map(children, (child, i) => {
                return <TabContent isVisible={currentTabIndex === i}>{child}</TabContent>;
            })}
        </>
    );
};

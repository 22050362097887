import type { ItemOfferedSchemaMarkup, ItemOfferedType } from '@config/schemaMarkup/type';
import type { Address } from '@type/location/address';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';

type ItemOfferedValueType = Partial<Record<Estate, ItemOfferedType>>;

const ITEM_OFFERED_TYPE_VALUE: ItemOfferedValueType = {
    [ESTATE.house]: 'SingleFamilyResidence',
    [ESTATE.flat]: 'Accommodation',
} as const;

export const getItemOfferedData = (
    estate: Estate,
    address: Address,
    addressCountry: string,
    additionalItemOfferedData?: Pick<ItemOfferedSchemaMarkup, 'numberOfRooms' | 'floorSize'>,
    seoDescription?: string,
): ItemOfferedSchemaMarkup => ({
    '@type': ITEM_OFFERED_TYPE_VALUE[estate] || 'Place',
    ...(seoDescription && { description: seoDescription }),
    address: {
        '@type': 'PostalAddress',
        addressCountry,
        ...(address?.city?.name && { addressLocality: address.city.name }),
        ...(address?.province?.name && { addressRegion: address.province.name }),
        ...(address?.street?.name && { streetAddress: address.street.name }),
    },
    ...(additionalItemOfferedData && { ...additionalItemOfferedData }),
});

import type { ServiceSectionItem } from '@config/home/type';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef } from 'react';

import { Description, Headline, IconWrapper, ServiceTile, StyledLinkButton } from './ServicesSection.theme';

interface Props {
    services: ServiceSectionItem[];
}

const { staticAssetsPrefix } = getPublicEnvConfig();

export const ServicesSection = ({ services }: Props): JSX.Element => {
    const [t] = useTranslations();
    const serviceSectionRef = useRef<HTMLDivElement | null>(null);
    const { trackEvent } = useTracking();

    useTrackImpression(serviceSectionRef, () => {
        trackEvent('seo_links_impression', {
            touch_point_button: 'services',
        });
    });

    const handleOnClick = (trackEventName: string, touchPointButton: string): void => {
        trackEvent(trackEventName, { touch_point_button: touchPointButton });
    };

    return (
        <NexusSectionWrapper
            headline={t('frontend.homepage.services-section.headline')}
            ref={serviceSectionRef}
            isScrollableHorizontally
        >
            <Slider
                items={services}
                trackingTouchPointButton="services"
                arrowsVisibility="none"
                shouldUseEdgePaddings
                renderItem={({ id, icon, headline, description, link, backgroundImage }): JSX.Element => (
                    <ServiceTile key={id} backgroundImage={`${staticAssetsPrefix}${backgroundImage}`}>
                        <IconWrapper>
                            <img src={`${staticAssetsPrefix}${icon}`} alt={t(headline)} />
                        </IconWrapper>
                        <Headline variant="bold">{t(headline)}</Headline>
                        <Description>{t(description)}</Description>
                        <LocalLink href={link.href} passHref>
                            <StyledLinkButton
                                variant="link"
                                onClick={() => handleOnClick(link.trackEventName, link.touchPointButton)}
                            >
                                {t(link.label)}
                            </StyledLinkButton>
                        </LocalLink>
                    </ServiceTile>
                )}
            />
        </NexusSectionWrapper>
    );
};

import { FAQ_LINK_REGEX } from '@domains/home/helpers/parseFaqAnswerToPlainText';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTracking } from '@lib/tracking/useTracking';
import { AccordionContent } from '@nexus/lib-react/dist/core/Accordion';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { HighlightedLink } from './NexusFaqAnswer.theme';

interface Props {
    children: string;
}

export const NexusFaqAnswer: FC<Props> = ({ children }) => {
    const { trackEvent } = useTracking();

    const handleClick = useCallback((): void => {
        trackEvent('seo_link_click', { touch_point_button: 'faq_answer' });
    }, [trackEvent]);

    const content = useMemo(() => {
        return replacePlaceholders(children, (text) => {
            const link = text.match(FAQ_LINK_REGEX);

            if (link) {
                const [, label, url] = link;

                return (
                    <HighlightedLink href={url} target="_blank" onClick={handleClick}>
                        {label}
                    </HighlightedLink>
                );
            }

            return text;
        });
    }, [children, handleClick]);

    return <AccordionContent>{content}</AccordionContent>;
};

import { SEARCH_BOX_ROW_LAYOUT, SEARCH_FORM_FIELDS_LAYOUT } from '@config/consts/searchBox/layout/home/otodompl';
import type { HomeConfig } from '@config/home/type';
import { SITE_CONFIG } from '@config/siteConfig';

export const HOME_CONFIG: HomeConfig = {
    canonicalURL: 'https://www.otodom.pl',
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_FORM_FIELDS_LAYOUT,
    seoSchemaMarkupData: {
        potentialAction: {
            target: '/[lang]/wyniki/sprzedaz/mieszkanie/cala-polska',
        },
        primaryImageOfPage: {
            url: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.homepageBackground}`,
            width: '1500',
            height: '522',
        },
    },
    isNexusHomePageAvailable: false,
    faqSectionItems: [
        {
            id: 'faq-01',
            questionKey: 'frontend.homepage.faq-section.question-1',
            answerKey: 'frontend.homepage.faq-section.answer-1',
        },
        {
            id: 'faq-02',
            questionKey: 'frontend.homepage.faq-section.question-2',
            answerKey: 'frontend.homepage.faq-section.answer-2',
        },
        {
            id: 'faq-03',
            questionKey: 'frontend.homepage.faq-section.question-3',
            answerKey: 'frontend.homepage.faq-section.answer-3',
        },
        {
            id: 'faq-04',
            questionKey: 'frontend.homepage.faq-section.question-4',
            answerKey: 'frontend.homepage.faq-section.answer-4',
        },
        {
            id: 'faq-05',
            questionKey: 'frontend.homepage.faq-section.question-5',
            answerKey: 'frontend.homepage.faq-section.answer-5',
        },
        {
            id: 'faq-06',
            questionKey: 'frontend.homepage.faq-section.question-6',
            answerKey: 'frontend.homepage.faq-section.answer-6',
        },
    ],
    propertiesForSellSection: [
        {
            id: 'sell-Warszawa',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWarsaw.webp',
            header: 'Warszawa',
            location: 'mazowieckie/warszawa/warszawa/warszawa',
            translatedLocation: {
                default: 'Warszawie',
            },
            cityLandingLocation: 'warszawa',
        },
        {
            id: 'sell-Wroclaw',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWroclaw.webp',
            header: 'Wrocław',
            location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
            translatedLocation: {
                default: 'Wrocławiu',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
            cityLandingLocation: 'wroclaw',
        },
        {
            id: 'sell-Krakow',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKrakow.webp',
            header: 'Kraków',
            location: 'malopolskie/krakow/krakow/krakow',
            translatedLocation: {
                default: 'Krakowie',
            },
            cityLandingLocation: 'krakow',
        },
        {
            id: 'sell-Poznan',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsPoznan.webp',
            header: 'Poznań',
            location: 'wielkopolskie/poznan/poznan/poznan',
            translatedLocation: {
                default: 'Poznaniu',
            },
            cityLandingLocation: 'poznan',
        },
        {
            id: 'sell-Gdansk',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdansk.webp',
            header: 'Gdańsk',
            location: 'pomorskie/gdansk/gdansk/gdansk',
            translatedLocation: {
                default: 'Gdańsku',
            },
            cityLandingLocation: 'gdansk',
        },
        {
            id: 'sell-Lodz',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsLodz.webp',
            header: 'Łódź',
            location: 'lodzkie/lodz/lodz/lodz',
            translatedLocation: {
                default: 'Łodzi',
            },
            cityLandingLocation: 'lodz',
        },
        {
            id: 'sell-Gdynia',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdynia.webp',
            header: 'Gdynia',
            location: 'pomorskie/gdynia/gdynia/gdynia',
            translatedLocation: {
                default: 'Gdyni',
            },
            cityLandingLocation: 'gdynia',
        },
        {
            id: 'sell-Katowice',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKatowice.webp',
            header: 'Katowice',
            location: 'slaskie/katowice/katowice/katowice',
            translatedLocation: {
                default: 'Katowicach',
            },
            cityLandingLocation: 'katowice',
        },
    ],
    propertiesForRentSection: [
        {
            id: 'rent-Warszawa',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWarsaw.webp',
            header: 'Warszawa',
            location: 'mazowieckie/warszawa/warszawa/warszawa',
            translatedLocation: {
                default: 'Warszawie',
            },
            cityLandingLocation: 'warszawa',
        },
        {
            id: 'rent-Wroclaw',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWroclaw.webp',
            header: 'Wrocław',
            location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
            translatedLocation: {
                default: 'Wrocławiu',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
            cityLandingLocation: 'wroclaw',
        },
        {
            id: 'rent-Krakow',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKrakow.webp',
            header: 'Kraków',
            location: 'malopolskie/krakow/krakow/krakow',
            translatedLocation: {
                default: 'Krakowie',
            },
            cityLandingLocation: 'krakow',
        },
        {
            id: 'rent-Poznan',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsPoznan.webp',
            header: 'Poznań',
            location: 'wielkopolskie/poznan/poznan/poznan',
            translatedLocation: {
                default: 'Poznaniu',
            },
            cityLandingLocation: 'poznan',
        },
        {
            id: 'rent-Gdansk',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdansk.webp',
            header: 'Gdańsk',
            location: 'pomorskie/gdansk/gdansk/gdansk',
            translatedLocation: {
                default: 'Gdańsku',
            },
            cityLandingLocation: 'gdansk',
        },
        {
            id: 'rent-Katowice',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKatowice.webp',
            header: 'Katowice',
            location: 'slaskie/katowice/katowice/katowice',
            translatedLocation: {
                default: 'Katowicach',
            },
            cityLandingLocation: 'katowice',
        },
        {
            id: 'rent-Gdynia',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdynia.webp',
            header: 'Gdynia',
            location: 'pomorskie/gdynia/gdynia/gdynia',
            translatedLocation: {
                default: 'Gdyni',
            },
            cityLandingLocation: 'gdynia',
        },
        {
            id: 'rent-Lodz',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsLodz.webp',
            header: 'Łódź',
            location: 'lodzkie/lodz/lodz/lodz',
            translatedLocation: {
                default: 'Łodzi',
            },
            cityLandingLocation: 'lodz',
        },
    ],
    propertiesFromPrimaryMarketSection: [
        {
            id: 'primary-market-Warszawa',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWarsaw.webp',
            header: 'Warszawa',
            location: 'mazowieckie/warszawa/warszawa/warszawa',
            translatedLocation: {
                default: 'Warszawie',
            },
            cityLandingLocation: 'warszawa',
        },
        {
            id: 'primary-market-Wroclaw',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsWroclaw.webp',
            header: 'Wrocław',
            location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
            translatedLocation: {
                default: 'Wrocławiu',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
            cityLandingLocation: 'wroclaw',
        },
        {
            id: 'primary-market-Krakow',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKrakow.webp',
            header: 'Kraków',
            location: 'malopolskie/krakow/krakow/krakow',
            translatedLocation: {
                default: 'Krakowie',
            },
            cityLandingLocation: 'krakow',
        },
        {
            id: 'primary-market-Poznan',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsPoznan.webp',
            header: 'Poznań',
            location: 'wielkopolskie/poznan/poznan/poznan',
            translatedLocation: {
                default: 'Poznaniu',
            },
            cityLandingLocation: 'poznan',
        },
        {
            id: 'primary-market-Gdansk',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdansk.webp',
            header: 'Gdańsk',
            location: 'pomorskie/gdansk/gdansk/gdansk',
            translatedLocation: {
                default: 'Gdańsku',
            },
            cityLandingLocation: 'gdansk',
        },
        {
            id: 'primary-market-Katowice',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsKatowice.webp',
            header: 'Katowice',
            location: 'slaskie/katowice/katowice/katowice',
            translatedLocation: {
                default: 'Katowicach',
            },
            cityLandingLocation: 'katowice',
        },
        {
            id: 'primary-market-Gdynia',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsGdynia.webp',
            header: 'Gdynia',
            location: 'pomorskie/gdynia/gdynia/gdynia',
            translatedLocation: {
                default: 'Gdyni',
            },
            cityLandingLocation: 'gdynia',
        },
        {
            id: 'primary-market-Lodz',
            imageUrl: '/images/homepage/popularLocationsSection/otodompl/popularLocationsLodz.webp',
            header: 'Łódź',
            location: 'lodzkie/lodz/lodz/lodz',
            translatedLocation: {
                default: 'Łodzi',
            },
            cityLandingLocation: 'lodz',
        },
    ],
    primaryMarketSection: {
        flatIconPath: '/images/homepage/primaryMarketSection/otodomFlat.svg',
        commercialIconPath: '/images/homepage/primaryMarketSection/otodomCommercialProperty.svg',
        availableSubSection: ['flat', 'house', 'commercial'],
    },
    usabilitySection: {
        hasBackground: true,
    },
    mortgageCalculatorSection: {
        linkUrl:
            '/[lang]/finanse?utm_source=otodom&utm_medium=banner&utm_content=Skorzystaj&utm_campaign=financing_homepage',
        image: {
            src: '/images/homepage/mortgageCalculatorSection/otodompl/mortgageCalculatorImage.webp?v=2',
            height: 370,
        },
    },
    sellersSection: {
        analyze: {
            linkUrl: 'https://analytics.otodom.pl',
            trackingData: {
                touchPointButton: 'for_professionals_otodom_analytics',
            },
        },
        improve: {
            linkUrl: 'https://agentpro.otodom.pl',
            trackingData: {
                touchPointButton: 'for_professionals_agent_pro',
            },
        },
        explore: {
            linkUrl: 'https://agentpro.otodom.pl/akademia',
            trackingData: {
                touchPointButton: 'for_professionals_academy',
            },
        },
    },
    analyticsSection: {
        linkUrl: '/wiadomosci/dane/otodom-analytics',
        imagePath: {
            desktop: '/images/homepage/analyticsSection/otodom-analytics-desktop.webp',
            mobile: '/images/homepage/analyticsSection/otodom-analytics-mobile.webp',
        },
    },
    servicesSection: [
        {
            id: '1',
            icon: '/images/homepage/servicesSection/otodompl/conciergeIcon.svg',
            backgroundImage: '/images/homepage/servicesSection/otodompl/conciergeBackground.webp',
            headline: 'frontend.homepage.services-section.otodom-concierge-headline',
            description: 'frontend.homepage.services-section.otodom-concierge-description-content',
            link: {
                label: 'frontend.homepage.services-section.link-label',
                href: 'https://concierge.otodom.pl/',
                trackEventName: 'seo_link_click',
                touchPointButton: 'services_concierge',
            },
        },
        {
            id: '2',
            icon: '/images/homepage/servicesSection/otodompl/financeIcon.svg',
            backgroundImage: '/images/homepage/servicesSection/otodompl/financeBackground.webp',
            headline: 'frontend.homepage.services-section.otodom-finanse-headline',
            description: 'frontend.homepage.services-section.otodom-finanse-description-content',
            link: {
                label: 'frontend.homepage.services-section.link-label',
                href: '/[lang]/finanse',
                trackEventName: 'finance_click',
                touchPointButton: 'services_finance',
            },
        },
    ],
    nexusUsabilitySection: {
        forSeekers: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-link-label',
                    href: '/wiadomosci/dane/otodom-analytics',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_data',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage1.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-link-label',
                    href: '/wiadomosci/pobierz',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_trends',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage2.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-link-label',
                    href: '/wiadomosci',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_knowledge',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage3.webp',
            },
        ],
        forProfessionals: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-link-label',
                    href: 'https://analytics.otodom.pl/#start',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_data',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage4.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-link-label',
                    href: 'https://agentpro.otodom.pl/',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_tools',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage5.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-link-label',
                    href: 'https://agentpro.otodom.pl/akademia',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_knowlegde',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage6.webp',
            },
        ],
    },
    nexusBrandingSection: {
        image: '/images/homepage/brandingSection/background.webp',
    },
    linkingSection: {
        images: {
            flatsOnSell: '/images/homepage/linkingSection/flatsOnSell.webp',
            housesOnRent: '/images/homepage/linkingSection/housesOnRent.webp',
            housesOnSell: '/images/homepage/linkingSection/housesOnSell.webp',
            terrainsOnSell: '/images/homepage/linkingSection/terrainOnSell.webp',
            commercialPropertiesOnSell: '/images/homepage/linkingSection/commercialPropertiesOnSell.webp',
            flatsOnRent: '/images/homepage/linkingSection/flatsOnRent.webp',
            roomsOnRent: '/images/homepage/linkingSection/roomsOnRent.webp',
            commercialPropertiesOnRent: '/images/homepage/linkingSection/commercialPropertiesOnRent.webp',
        },
    },
};

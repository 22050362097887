import type { SchemaMarkupData } from '@config/schemaMarkup/type';
import { logWarn } from '@domains/shared/helpers/logger';

export const getStringifySchemaMarkup = (schemaMarkupData: SchemaMarkupData | null): string | undefined => {
    if (!schemaMarkupData) {
        return;
    }

    try {
        return JSON.stringify(schemaMarkupData);
    } catch {
        logWarn('Cannot stringify a schema markup script');

        return;
    }
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Accordion, AccordionHeader } from '@nexus/lib-react/dist/core/Accordion';

export const StyledAccordion = styled(Accordion)`
    width: 100%;
    padding: 0 ${theme.space[24]};

    header + div {
        height: auto;
    }
`;

export const StyledAccordionHeader = styled(AccordionHeader)`
    font-weight: ${theme.fontWeights[700]};
`;

import { LinkButton } from '@domains/shared/components/nexus/LinkButton/LinkButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { H2, P3 } from '@nexus/lib-react/dist/core/Typography';

export const ContentWrapper = styled.div`
    display: grid;
    grid-template: 1fr / 1fr;
    align-items: center;
    justify-content: center;
    padding: ${theme.space[32]} ${theme.space[24]};
    background-color: ${theme.colors.$01_white};
    gap: ${theme.space[24]};

    @media ${theme.mediaQueries.smMin} {
        grid-template: 1fr / 210px 1fr;
        padding: ${theme.space[32]} ${theme.space[16]};
    }

    @media ${theme.mediaQueries.mdMin} {
        grid-template: 1fr / 272px 1fr;
        padding: ${theme.space[32]} ${theme.space[24]};
    }

    @media ${theme.mediaQueries.lgMin} {
        grid-template: 1fr / 280px 1fr;
        gap: 56px;
    }

    @media ${theme.mediaQueries.xlMin} {
        grid-template: 1fr / 328px 1fr;
        padding: ${theme.space[32]};
    }
`;

export const StyledImage = styled.img`
    width: 240px;
    height: auto;
    margin: 0 0 ${theme.space[32]};

    @media ${theme.mediaQueries.smMin} {
        width: 210px;
        margin: 0;
    }

    @media ${theme.mediaQueries.mdMin} {
        width: 272px;
    }

    @media ${theme.mediaQueries.lgMin} {
        width: 280px;
    }

    @media ${theme.mediaQueries.xlMin} {
        width: 328px;
    }
`;

export const BrandingSectionHeadline = styled(H2)`
    margin-bottom: ${theme.space[16]};
    font-size: ${theme.fontSizes[20]};
    line-height: ${theme.lineHeights[22]};
    text-align: left;

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: ${theme.space[32]};
        font-size: ${theme.typography.h2.fontSize};
        line-height: ${theme.typography.h2.lineHeight};
    }
`;

export const BrandingSectionParagraph = styled(P3)`
    margin: 0 0 ${theme.space[16]};
    color: ${theme.colors['text-global-secondary']};

    @media ${theme.mediaQueries.lgMin} {
        margin: 0 0 ${theme.space[32]};
    }
`;

export const StyledLinkButton = styled(LinkButton)`
    width: 100%;

    @media ${theme.mediaQueries.smMin} {
        width: auto;
    }
`;

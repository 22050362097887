import type { AdTarget as AdPageTargeting } from '@domains/ad/types/Ad';
import type { Targeting as ListingPageTargeting } from '@domains/search/types/targeting';
import { DFP_USER_ID_COOKIE } from '@domains/shared/components/Advertising/consts/constants';
import type { Targeting } from '@lib/baxter/baxter';
import { initSlots } from '@lib/baxter/baxter';
import { useCookieStorage, waitForUserDecision } from '@olxeu-eprivacy-storage/react';
import { useEffect } from 'react';

const PAGE_NAME = {
    ad: 'ad',
    listing: 'listing',
    home: 'homepage',
} as const;

type PageNameKey = keyof typeof PAGE_NAME;

type PageNameValue = (typeof PAGE_NAME)[PageNameKey];

interface ExtraParams {
    adId: string | null;
    adPrice: number | null;
    adPhoto: string | null;
    adTitle: string | null;
}

interface AdRequest {
    id: string;
    pageName: PageNameValue;
    targetingParams?: Targeting;
    extraParams?: Partial<ExtraParams>;
}

let currentPageId: string | null = '';

const hasPageChanged = (id: string): boolean => currentPageId !== id;
const setCurrentPage = (id: string): string => (currentPageId = id);
const addBrowserCompatible = (targeting: Targeting): void => {
    window.GPT = {
        targeting,
    };
    window.baxterTargeting = targeting;
};

export const getAdverts = (adRequest: AdRequest): void => {
    const { id } = adRequest;

    if (!hasPageChanged(id)) {
        return;
    }

    setCurrentPage(id);
    const { targetingParams: targeting, pageName } = adRequest;

    if (targeting) {
        addBrowserCompatible(targeting);
    }

    initSlots(pageName, targeting);
};

type UseBaxterSlots =
    | {
          target: 'homePage';
          params?: null;
      }
    | { target: 'listingPage'; params: ListingPageTargeting }
    | { target: 'adPage'; params: Partial<AdPageTargeting> | undefined };
export const useBaxterSlots = ({ target, params }: UseBaxterSlots): void => {
    const cookieStorage = useCookieStorage();
    useEffect(() => {
        let isMounted = true;
        // Wait for the cookie decision as otherwise DFP_USER_ID_COOKIE is unknown
        waitForUserDecision().finally(() => {
            if (!isMounted) {
                return;
            }
            const dfpCookieValue = cookieStorage.get(DFP_USER_ID_COOKIE) || window.reDfpUserId || '';
            switch (target) {
                case 'homePage': {
                    return getAdverts({ id: 'homepage', pageName: PAGE_NAME.home });
                }
                case 'listingPage': {
                    const id = params?.Id || '';

                    return getAdverts({
                        id: `listing_${id}`,
                        pageName: PAGE_NAME.listing,
                        targetingParams: { ...params, [DFP_USER_ID_COOKIE]: dfpCookieValue } as Targeting,
                    });
                }
                case 'adPage': {
                    const id = params?.Id || '';
                    const targetingParams = {
                        ad_id: id,
                        [DFP_USER_ID_COOKIE]: dfpCookieValue,
                        ...params,
                    };

                    return getAdverts({
                        id,
                        pageName: PAGE_NAME.ad,
                        targetingParams,
                        extraParams: {
                            adPrice: params?.Price,
                            adTitle: params?.Title,
                            adPhoto: params?.Photo,
                        },
                    });
                }
            }
        });

        return (): void => {
            isMounted = false;
        };
    }, [cookieStorage, params, target]);
};

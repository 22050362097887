export const FAQ_LINK_REGEX = /\[([^[\]]*?)]\((https:\/\/[^(]*?)\)/;

export const parseFaqAnswerToPlainText = (text: string): string =>
    text
        .split('<C>')
        .map((textFragment, index): string => {
            if (index % 2 === 0) return textFragment;

            const link = textFragment.match(FAQ_LINK_REGEX);

            if (link) {
                const [, label] = link;

                return label;
            }

            return text;
        })
        .join('');

import { SCHEMA_MARKUP_CONFIG } from '@config/schemaMarkup/schemaMarkupConfig';
import type {
    BasePotentialActionSchemaMarkup,
    PotentialActionSchemaMarkup,
    SearchActionSchemaMarkup,
} from '@config/schemaMarkup/type';
import type { Locale } from '@lib/i18n/types/locale';

export interface SearchActionSchemaProps {
    potentialAction: Omit<PotentialActionSchemaMarkup, keyof BasePotentialActionSchemaMarkup>;
    lang: Locale;
    urlPath?: string;
}

export const generateSearchActionSchemaMarkup = ({
    urlPath = '',
    lang,
    potentialAction,
}: SearchActionSchemaProps): SearchActionSchemaMarkup | undefined => {
    const initialSearchActionConfig = SCHEMA_MARKUP_CONFIG.searchAction;

    if (!initialSearchActionConfig) return;

    const localisedTargetPath = potentialAction.target.replace('[lang]', lang);

    return {
        ...initialSearchActionConfig,
        url: `${initialSearchActionConfig.url}${urlPath}`,
        potentialAction: {
            ...initialSearchActionConfig.potentialAction,
            target: `${initialSearchActionConfig.url}${localisedTargetPath}?description={search_term_string}`,
        },
    };
};

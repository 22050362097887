import { LinkButton } from '@domains/shared/components/nexus/LinkButton/LinkButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2, P3 } from '@nexus/lib-react/dist/core/Typography';
import type { CSSProperties } from 'react';

export const ServiceTile = styled.div<{ backgroundImage: string }>`
    display: flex;
    position: relative;
    flex-flow: column;
    align-items: flex-start;
    width: 296px;
    padding: ${theme.space[16]} ${theme.space[24]};
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.smMin} {
        width: auto;
    }

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space[40]} ${theme.space[24]};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[40]} ${theme.space[24]} ${theme.space[40]} 190px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 166px;
            background-image: ${({ backgroundImage }): CSSProperties['backgroundImage'] => `url(${backgroundImage})`};
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.sizes[48]};
    height: ${theme.sizes[48]};
    margin: 0 0 ${theme.space[8]};
`;

export const Headline = styled(P2)`
    margin: 0 0 ${theme.space[8]};
`;

export const Description = styled(P3)`
    margin: 0 0 ${theme.space[8]};
    color: ${theme.colors['text-global-secondary']};
`;

export const StyledLinkButton = styled(LinkButton)`
    margin: auto 0 0;
`;

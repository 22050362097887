import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const SubHeadline = styled(P3)`
    margin: 0 0 ${theme.space[24]};
    padding: 0 ${theme.space[16]};
    color: ${theme.colors['text-global-secondary']};

    @media ${theme.mediaQueries.mdMin} {
        padding: 0;
    }
`;

import { SCHEMA_MARKUP_CONFIG } from '@config/schemaMarkup/schemaMarkupConfig';
import type { PrimaryImageOfPageSchemaMarkup, WebPageSchemaMarkup } from '@config/schemaMarkup/type';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { generateBreadcrumbSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateBreadcrumbSchemaMarkup';
import type { Locale } from '@lib/i18n/types/locale';

export interface WebPageSchemaProps {
    name: string;
    description: string;
    urlPath?: string;
    headline?: string | null;
    lang: Locale;
    primaryImageOfPage?: Pick<PrimaryImageOfPageSchemaMarkup, 'url' | 'width' | 'height'>;
    breadcrumbItems?: Breadcrumb[] | null;
    isPartOfDescription: string;
}

export const generateWebPageSchemaMarkup = ({
    name,
    description,
    urlPath = '',
    headline,
    lang,
    primaryImageOfPage,
    breadcrumbItems,
    isPartOfDescription,
}: WebPageSchemaProps): WebPageSchemaMarkup | undefined => {
    const initialWebPageConfig = SCHEMA_MARKUP_CONFIG.webPage;
    if (!initialWebPageConfig) return;
    const breadcrumbSchema = generateBreadcrumbSchemaMarkup(breadcrumbItems, initialWebPageConfig.url);

    return {
        ...initialWebPageConfig,
        name,
        description,
        ...(headline && { headline }),
        url: `${initialWebPageConfig.url}${urlPath}`,
        isPartOf: {
            ...initialWebPageConfig.isPartOf,
            url: initialWebPageConfig.url,
            description: isPartOfDescription,
            inLanguage: lang,
        },
        ...(primaryImageOfPage && {
            primaryImageOfPage: {
                '@type': 'ImageObject',
                inLanguage: lang,
                ...primaryImageOfPage,
            },
        }),
        ...(breadcrumbSchema && { breadcrumb: breadcrumbSchema }),
    };
};

import { SCHEMA_MARKUP_CONFIG } from '@config/schemaMarkup/schemaMarkupConfig';
import type { OfferSchemaMarkup, ProductSchemaMarkup } from '@config/schemaMarkup/type';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { getAdditionalItemOfferedData } from '@domains/shared/helpers/schemaMarkup/getAdditionalItemOfferedData';
import { getItemOfferedData } from '@domains/shared/helpers/schemaMarkup/getItemOfferedData';
import { getOfferData } from '@domains/shared/helpers/schemaMarkup/getOfferData';
import type { Locale } from '@lib/i18n/types/locale';
import { ESTATE } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';

interface ProductSchemaProps {
    businessFunction: Transaction;
    description: string;
    imageUrl?: string;
    items: AdvertListItem[];
    lang: Locale;
    name: string;
    urlPath?: string;
}

export const generateProductSchemaMarkup = ({
    businessFunction,
    description,
    imageUrl,
    items,
    lang,
    name,
    urlPath = '',
}: ProductSchemaProps): ProductSchemaMarkup | undefined => {
    const initialProductConfig = SCHEMA_MARKUP_CONFIG.product;

    if (!initialProductConfig) return;

    const {
        additionalType,
        url,
        adUrlPrefix,
        addressCountry: { name: addressCountry },
    } = initialProductConfig;

    const offersPriceCurrency = items[0]?.totalPrice?.currency;
    const prices = items.map((item) => Number(item.totalPrice?.value)).filter((price) => !Number.isNaN(price));
    const highPrice = prices.length > 0 && `${Math.max(...prices)}`;
    const lowPrice = prices.length > 0 && `${Math.min(...prices)}`;

    const offerItems: OfferSchemaMarkup[] = items.map((adData) => {
        const { title, images, totalPrice, slug, location, estate, shortDescription } = adData;
        const advertUrl = `${url}${adUrlPrefix}${slug}`;
        const localizedAdvertUrl = advertUrl.replace('[lang]', lang);
        const additionalItemOfferedData =
            estate === ESTATE.flat || estate === ESTATE.house ? getAdditionalItemOfferedData(adData) : undefined;
        const itemOffered = getItemOfferedData(
            estate,
            location?.address,
            addressCountry,
            additionalItemOfferedData,
            shortDescription || undefined,
        );

        return {
            '@type': 'Offer',
            availability: 'InStock',
            ...getOfferData(images, title, totalPrice, localizedAdvertUrl, itemOffered),
        };
    });

    return {
        '@type': 'Product',
        additionalType,
        name,
        ...(imageUrl && { image: imageUrl }),
        description,
        url: `${url}${urlPath}`,
        offers: {
            '@type': 'AggregateOffer',
            businessFunction,
            ...(highPrice && { highPrice }),
            ...(lowPrice && { lowPrice }),
            ...(offersPriceCurrency && { priceCurrency: offersPriceCurrency }),
            offerCount: `${items.length}`,
            offers: offerItems,
        },
    };
};

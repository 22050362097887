import type { HomepageAd, HomepageAgency } from '@domains/home/types/homepageAd';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { AD_PAGE_TYPE } from '@domains/shared/consts/adPageType';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTracking } from '@lib/tracking/useTracking';
import { ESTATE } from '@type/search/filters/estate';
import type { JSX } from 'react';
import { useMemo, useRef } from 'react';

import {
    AgencyLabel,
    AgencyPrivateLabel,
    BottomContainer,
    Image,
    Price,
    Tile,
    TILE_WIDTH,
    Title,
} from './NexusPromotedOffers.theme';

interface Props {
    promotedOffer: HomepageAd;
}

export const NexusPromotedOffer = ({ promotedOffer }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const isInvestment = promotedOffer.estate === ESTATE.investment;

    const adPageTypeUrlPart = isInvestment ? AD_PAGE_TYPE.investment : AD_PAGE_TYPE.ad;
    const [t] = useTranslations();

    const offerRef = useRef<HTMLAnchorElement>(null);

    const promotedOfferTrackingData = useMemo(
        () => ({
            touch_point_button: 'promoted_ads',
            ad_id: promotedOffer.id,
        }),
        [promotedOffer.id],
    );

    const handleOnClick = (): void => {
        trackEvent('ad_click', promotedOfferTrackingData);
    };

    useTrackImpression(offerRef, () => {
        trackEvent('ad_impressions', promotedOfferTrackingData);
    });

    const renderBottomLabel = (agency: HomepageAgency | null): ReactJSXElement => {
        if (!agency) {
            return <AgencyPrivateLabel>{t('frontend.home.promoted-offers-section.private-label')}</AgencyPrivateLabel>;
        }

        return (
            <>
                <AgencyLabel variant="bold">{agency.name}</AgencyLabel>
                <AgencyLabel>
                    {agency.type === 'DEVELOPER'
                        ? t('frontend.home.promoted-offers-section.developer-label')
                        : t('frontend.home.promoted-offers-section.real-estate-agency-label')}
                </AgencyLabel>
            </>
        );
    };

    return (
        <LocalLink href={`/[lang]/${adPageTypeUrlPart}/${promotedOffer?.slug}`} passHref>
            <Tile onClick={handleOnClick} ref={offerRef} data-testid="offer-card" data-cy="offer-card">
                <Image
                    src={promotedOffer?.images[0]?.medium}
                    alt={promotedOffer.title}
                    width={TILE_WIDTH}
                    height="166"
                    loading="lazy"
                    decoding="async"
                />
                <BottomContainer>
                    <Price>
                        {promotedOffer.totalPrice?.value
                            ? getFormattedPrice(promotedOffer.totalPrice)
                            : t('frontend.home.promoted-offers-section.ask-price')}
                    </Price>
                    <Title>{promotedOffer.locationLabel.value}</Title>
                    {renderBottomLabel(promotedOffer.agency)}
                </BottomContainer>
            </Tile>
        </LocalLink>
    );
};

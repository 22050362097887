import { HOME_CONFIG } from '@config/home/homeConfig';
import type { HorizontalTile } from '@domains/home/types/horizontalTiles';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { useMemo } from 'react';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const useGetPropertiesForSellAndForRent = (): {
    propertiesForSell: HorizontalTile[];
    propertiesForRent: HorizontalTile[];
} => {
    const location = URL_LOCATION_FILLER.allLocations.label;
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const { linkingSection } = HOME_CONFIG;
    const linkingSectionImages = linkingSection.images;

    const propertiesForSell = useMemo(
        () => [
            {
                id: 'flats',
                label: t('frontend.homepage.props-for-sell-section.flats'),
                title: t('frontend.homepage.props-for-sell-section.tile-title', {
                    estate: t('frontend.homepage.props-for-sell-section.flats'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.sell,
                    estate: ESTATE.flat,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.flatsOnSell}`,
            },
            {
                id: 'houses',
                label: t('frontend.homepage.props-for-sell-section.houses'),
                title: t('frontend.homepage.props-for-sell-section.tile-title', {
                    estate: t('frontend.homepage.props-for-sell-section.houses'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.sell,
                    estate: ESTATE.house,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.housesOnSell}`,
            },
            {
                id: 'terrains',
                label: t('frontend.homepage.props-for-sell-section.terrains'),
                title: t('frontend.homepage.props-for-sell-section.tile-title', {
                    estate: t('frontend.homepage.props-for-sell-section.terrains'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.sell,
                    estate: ESTATE.terrain,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.terrainsOnSell}`,
            },
            {
                id: 'commercial',
                label: t('frontend.homepage.props-for-sell-section.commercialProperties'),
                title: t('frontend.homepage.props-for-sell-section.tile-title', {
                    estate: t('frontend.homepage.props-for-sell-section.commercialProperties'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.sell,
                    estate: ESTATE.commercialProperty,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.commercialPropertiesOnSell}`,
            },
        ],
        [lang, location, t, linkingSectionImages],
    );

    const propertiesForRent = useMemo(
        () => [
            {
                id: 'rent-flats',
                label: t('frontend.homepage.props-for-rent-section.flats'),
                title: t('frontend.homepage.props-for-rent-section.tile-title', {
                    estate: t('frontend.homepage.props-for-rent-section.flats'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.rent,
                    estate: ESTATE.flat,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.flatsOnRent}`,
            },
            {
                id: 'rent-houses',
                label: t('frontend.homepage.props-for-rent-section.houses'),
                title: t('frontend.homepage.props-for-rent-section.tile-title', {
                    estate: t('frontend.homepage.props-for-rent-section.houses'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.rent,
                    estate: ESTATE.house,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.housesOnRent}`,
            },
            {
                id: 'rent-rooms',
                label: t('frontend.homepage.props-for-rent-section.rooms'),
                title: t('frontend.homepage.props-for-rent-section.tile-title', {
                    estate: t('frontend.homepage.props-for-rent-section.rooms'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.rent,
                    estate: ESTATE.room,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.roomsOnRent}`,
            },
            {
                id: 'rent-commercial',
                label: t('frontend.homepage.props-for-rent-section.commercialProperties'),
                title: t('frontend.homepage.props-for-rent-section.tile-title', {
                    estate: t('frontend.homepage.props-for-rent-section.commercialProperties'),
                }),
                url: buildSearchUrl({
                    lang,
                    transaction: TRANSACTION.rent,
                    estate: ESTATE.commercialProperty,
                    location,
                }),
                image: `${staticAssetsPrefix}${linkingSectionImages.commercialPropertiesOnRent}`,
            },
        ],
        [lang, location, t, linkingSectionImages],
    );

    return {
        propertiesForRent,
        propertiesForSell,
    };
};

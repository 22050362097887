import { HOME_CONFIG } from '@config/home/homeConfig';
import type { FaqSchemaItemData, SchemaMarkupData } from '@config/schemaMarkup/type';
import { parseFaqAnswerToPlainText } from '@domains/home/helpers/parseFaqAnswerToPlainText';
import { generateSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateSchemaMarkup';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useMemo } from 'react';

export const useGetSeoSchemaMarkup = (): SchemaMarkupData | null => {
    const { seoSchemaMarkupData: homePageSchemaMarkupData, faqSectionItems } = HOME_CONFIG;
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const parsedFaqSchemaData: FaqSchemaItemData[] = useMemo(() => {
        return faqSectionItems
            ? faqSectionItems.map(({ questionKey, answerKey }) => ({
                  question: t(questionKey),
                  answer: parseFaqAnswerToPlainText(t(answerKey)),
              }))
            : [];
    }, [faqSectionItems, t]);

    return useMemo<SchemaMarkupData | null>(() => {
        return homePageSchemaMarkupData
            ? generateSchemaMarkup({
                  pageSchemaData: {
                      ...homePageSchemaMarkupData,
                      name: t('frontend.homepage.meta.page-title'),
                      description: t('frontend.homepage.meta.page-description'),
                      isPartOfDescription: t('frontend.shared.schema-markup.is-part-of.description'),
                      lang,
                  },
                  searchActionSchemaData: {
                      potentialAction: homePageSchemaMarkupData.potentialAction,
                      lang,
                  },
                  faqSchemaData: parsedFaqSchemaData,
              })
            : null;
    }, [lang, parsedFaqSchemaData, homePageSchemaMarkupData, t]);
};

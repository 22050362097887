import type { ItemOfferedSchemaMarkup } from '@config/schemaMarkup/type';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';

type RoomNumbersMapType = {
    [k in RoomsNumber]?: number;
};

/* eslint-disable @typescript-eslint/naming-convention -- RoomNumber entries are UpperCase */
const ROOM_NUMBERS_MAP: RoomNumbersMapType = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

export const getAdditionalItemOfferedData = (
    adData: AdvertListItem,
): Pick<ItemOfferedSchemaMarkup, 'numberOfRooms' | 'floorSize'> | undefined => {
    const { areaInSquareMeters, roomsNumber } = adData;

    if (!areaInSquareMeters || !roomsNumber) return;

    /**
     * We only support ads with max 10 rooms, for the rest of the ads we don't include numberOfRooms property
     */
    return {
        ...(ROOM_NUMBERS_MAP[roomsNumber] && { numberOfRooms: ROOM_NUMBERS_MAP[roomsNumber] }),
        floorSize: {
            '@type': 'QuantitativeValue',
            value: Number(areaInSquareMeters),
            unitCode: 'MTK',
        },
    };
};

import type { ItemOfferedSchemaMarkup, OfferSchemaMarkup } from '@config/schemaMarkup/type';
import type { ListingItemPrice } from '@type/pricing/listingItemPrice';

export const getOfferData = (
    images: Array<{
        medium: string;
        large: string;
    }>,
    title: string,
    totalPrice: ListingItemPrice | null,
    localizedAdvertUrl: string,
    itemOffered: ItemOfferedSchemaMarkup,
): Omit<OfferSchemaMarkup, '@type' | 'availability'> => ({
    ...(images && images[0]?.medium && { image: images[0].medium }),
    ...(totalPrice?.value && { price: totalPrice.value }),
    ...(totalPrice?.currency && { priceCurrency: totalPrice.currency }),
    name: title,
    url: localizedAdvertUrl,
    itemOffered,
});

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P1, P3, P5 } from '@nexus/lib-react/dist/core/Typography';

export const TILE_WIDTH = 296;

export const Tile = styled.a`
    display: block;
    width: ${TILE_WIDTH}px;
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
    text-decoration: none;
`;

export const BottomContainer = styled.span`
    display: block;
    padding: ${theme.space['16']};
`;

export const Image = styled.img`
    display: block;
    object-fit: cover;
`;

export const Title = styled(P3)`
    display: -webkit-box;
    margin-bottom: ${theme.space['8']};
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${theme.colors['text-global-secondary']};
`;

export const Price = styled(P1)`
    margin-bottom: ${theme.space[8]};
    font-size: ${theme.sizes[20]};
    font-weight: ${theme.fontWeights.bold};
`;

export const AgencyLabel = styled(P5)`
    color: ${theme.colors['text-global-secondary']};
`;

export const AgencyPrivateLabel = styled(P5)`
    position: relative;
    top: 14px;
    color: ${theme.colors['text-global-secondary']};
`;

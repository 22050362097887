import type { FaqItem } from '@config/home/homeConfig';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC, ReactElement } from 'react';
import { useRef } from 'react';

import { NexusFaqAnswer } from './components/NexusFaqAnswer/NexusFaqAnswer';
import { StyledAccordion, StyledAccordionHeader } from './NexusFaqSection.theme';

interface Props {
    items: FaqItem[];
}

const getAccordionHeaderComponent = (content: string): ReactElement => {
    return <StyledAccordionHeader>{content}</StyledAccordionHeader>;
};

const getAccordionContentComponent = (content: string): ReactElement => {
    return <NexusFaqAnswer>{content}</NexusFaqAnswer>;
};

export const NexusFaqSection: FC<Props> = ({ items }) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const faqRef = useRef<HTMLDivElement | null>(null);

    const handleClick = (id: string): void => {
        trackEvent('faq_expand_click', { touch_point_button: id });
    };

    useTrackImpression(faqRef, () => {
        trackEvent('faq_impression', { touch_point_button: 'faq' });
    });

    return (
        <NexusSectionWrapper headline={t('frontend.homepage.nexus-faq-section.header')}>
            <StyledAccordion
                innerRef={faqRef}
                dataAttributes={{
                    testid: 'nexus-faq-component',
                }}
                onItemOpen={handleClick}
                content={items.map((item) => ({
                    id: item.id,
                    header: () => getAccordionHeaderComponent(t(item.questionKey)),
                    content: () => getAccordionContentComponent(t(item.answerKey)),
                }))}
            />
        </NexusSectionWrapper>
    );
};

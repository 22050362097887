import type { Money } from '@type/pricing/money';

/**
 * Returns a price in the local format
 *
 * @example
 * getFormattedPrice({ value: 714934.5, currency: 'PLN' })
 * @returns 714 934,50 zł
 */
export const getFormattedPrice = ({ value, currency }: Money): string => {
    const { locale, code } = CURRENCIES[currency];

    return (
        new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: code,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            // finds double zeroes - 00 - and replaces it
            .replace(/\D00(?=\D*$)/, '')
    );
};

/* eslint-disable @typescript-eslint/naming-convention  -- conflicts with union of currency types `Currency` in `advertListItem` */
const CURRENCIES: Record<string, { locale: string; code: string }> = {
    PLN: {
        locale: 'pl-PL',
        code: 'PLN',
    },
    EUR: {
        locale: 'pt-PT',
        code: 'EUR',
    },
    UAH: {
        locale: 'uk-UA',
        code: 'UAH',
    },
    USD: {
        locale: 'us-US',
        code: 'USD',
    },
    GBP: {
        locale: 'en-GB',
        code: 'GBP',
    },
    RON: {
        locale: 'ro-RO',
        code: 'RON',
    },
};
/* eslint-enable @typescript-eslint/naming-convention */

import { SITE_CONFIG } from '@config/siteConfig';
import { getErrorType, logError, logWarn } from '@domains/shared/helpers/logger';
import Script from 'next/script';
import type { JSX } from 'react';
import { memo } from 'react';

interface Props {
    shouldRenderAds: boolean;
}

const getBaxterScriptURLs = (): Record<string, string> => ({
    baxterUrl: SITE_CONFIG?.baxter?.urlInit || '',
    baxterStyling: SITE_CONFIG?.baxter?.urlStyling || '',
});

const handleError = (error: unknown): void => {
    const logMethod = getErrorType(error) === 'not_defined' ? logWarn : logError;

    logMethod('[BaxterScripts][slots] script loading error', { error });
};

export const BaseBaxterScripts = ({ shouldRenderAds }: Props): JSX.Element | null => {
    const baxterScriptPaths = getBaxterScriptURLs();

    if (!shouldRenderAds || baxterScriptPaths.baxterUrl === '' || baxterScriptPaths.baxterStyling === '') {
        return null;
    }

    return (
        <>
            <link href={baxterScriptPaths.baxterStyling} id="baxter-style-slots" />
            <Script
                defer
                strategy="lazyOnload"
                id="baxter-script-slots"
                src={baxterScriptPaths.baxterUrl}
                onError={handleError}
            />
        </>
    );
};

export const BaxterScripts = memo(BaseBaxterScripts);

import type { TranslatedLocation } from '@config/home/homeConfig';
import { HOME_CONFIG } from '@config/home/homeConfig';
import type { LinksTile } from '@domains/home/types/linksTile';
import { stringCapitalize } from '@domains/shared/helpers/stringCapitalize';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { useCallback, useMemo } from 'react';

/* eslint-disable no-restricted-syntax -- this block of invalid translation keys requires a separate task */
export const useGetPropertiesForSellAndForRentFromPopularLocations = (): {
    propertiesForSell: LinksTile[];
    propertiesForRent: LinksTile[];
    propertiesFromPrimaryMarket: LinksTile[];
} => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const {
        propertiesForSellSection,
        propertiesForRentSection,
        propertiesFromPrimaryMarketSection,
        primaryMarketSection,
    } = HOME_CONFIG;

    const getTranslatedLocation = useCallback(
        (translatedLocation: TranslatedLocation, translationKey?: string) =>
            t(translationKey ?? 'frontend.homepage.popular-locations-section.link.label.primary', {
                location: translatedLocation[lang] || translatedLocation.default,
            }),
        [lang, t],
    );

    const propertiesForSell = useMemo(
        () =>
            propertiesForSellSection?.map((propertyForSell) => {
                const translatedLocation = getTranslatedLocation(
                    propertyForSell.translatedLocation,
                    propertyForSell.translationKey,
                );

                return {
                    id: propertyForSell.id,
                    imageUrl: propertyForSell.imageUrl,
                    location: propertyForSell.location,
                    header: propertyForSell.header,
                    links: [
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.flats'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.flat,
                                location: propertyForSell.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.studioFlat,
                                location: propertyForSell.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.houses'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.houses'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.houses'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.house,
                                location: propertyForSell.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.terrains'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.terrains'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.terrains'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.terrain,
                                location: propertyForSell.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.sell'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.commercialProperty,
                                location: propertyForSell.location,
                            }),
                        },
                    ],
                    cityLandingLocation: propertyForSell.cityLandingLocation,
                };
            }),
        [getTranslatedLocation, lang, propertiesForSellSection, t],
    );

    const propertiesForRent = useMemo(
        () =>
            propertiesForRentSection?.map((property) => {
                const translatedLocation = getTranslatedLocation(property.translatedLocation, property.translationKey);

                return {
                    id: property.id,
                    imageUrl: property.imageUrl,
                    location: property.location,
                    header: property.header,
                    links: [
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.flats'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.rent,
                                estate: ESTATE.flat,
                                location: property.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.studio-flats'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.rent,
                                estate: ESTATE.studioFlat,
                                location: property.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.houses'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.houses'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.houses'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.rent,
                                estate: ESTATE.house,
                                location: property.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.rooms'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.rooms'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.rooms'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.rent,
                                estate: ESTATE.room,
                                location: property.location,
                            }),
                        },
                        {
                            label: t('frontend.homepage.popular-locations-section.link.label', {
                                estate: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                            }),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                            title: t('frontend.homepage.popular-locations-section.link.title', {
                                estate: t('frontend.homepage.popular-locations-section.estate.commercialProperties'),
                                transaction: t('frontend.homepage.popular-locations-section.transaction.rent'),
                                location: translatedLocation,
                            }),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.rent,
                                estate: ESTATE.commercialProperty,
                                location: property.location,
                            }),
                        },
                    ],
                    cityLandingLocation: property.cityLandingLocation,
                };
            }),
        [getTranslatedLocation, lang, propertiesForRentSection, t],
    );

    const propertiesFromPrimaryMarket = useMemo(
        () =>
            propertiesFromPrimaryMarketSection?.map((property) => {
                const translatedLocation = getTranslatedLocation(property.translatedLocation, property.translationKey);

                return {
                    id: property.id,
                    imageUrl: property.imageUrl,
                    location: property.location,
                    header: property.header,
                    links: [
                        {
                            label: stringCapitalize(
                                t('frontend.homepage.popular-locations-section.link.primary-label', {
                                    estate: t('frontend.homepage.popular-locations-section.estate.flats').toLowerCase(),
                                }),
                                'partial',
                            ),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.flats'),
                            title: stringCapitalize(
                                t('frontend.homepage.popular-locations-section.link.primary-title', {
                                    estate: t('frontend.homepage.popular-locations-section.estate.flats').toLowerCase(),
                                    location: translatedLocation,
                                }),
                                'partial',
                            ),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.flat,
                                location: property.location,
                                market: 'PRIMARY',
                            }),
                        },
                        {
                            label: stringCapitalize(
                                t('frontend.homepage.popular-locations-section.link.primary-label', {
                                    estate: t(
                                        'frontend.homepage.popular-locations-section.estate.houses',
                                    ).toLowerCase(),
                                }),
                                'partial',
                            ),
                            estateLabel: t('frontend.homepage.popular-locations-section.estate.houses'),
                            title: stringCapitalize(
                                t('frontend.homepage.popular-locations-section.link.primary-title', {
                                    estate: t(
                                        'frontend.homepage.popular-locations-section.estate.houses',
                                    ).toLowerCase(),
                                    location: translatedLocation,
                                }),
                                'partial',
                            ),
                            url: buildSearchUrl({
                                lang,
                                transaction: TRANSACTION.sell,
                                estate: ESTATE.house,
                                location: property.location,
                                market: 'PRIMARY',
                            }),
                        },
                        ...(primaryMarketSection?.availableSubSection.includes('commercial')
                            ? [
                                  {
                                      label: stringCapitalize(
                                          t('frontend.homepage.popular-locations-section.link.primary-label', {
                                              estate: t(
                                                  'frontend.homepage.popular-locations-section.estate.commercialProperties',
                                              ).toLowerCase(),
                                          }),
                                          'partial',
                                      ),
                                      estateLabel: t(
                                          'frontend.homepage.popular-locations-section.estate.commercialProperties',
                                      ),
                                      title: stringCapitalize(
                                          t('frontend.homepage.popular-locations-section.link.primary-title', {
                                              estate: t(
                                                  'frontend.homepage.popular-locations-section.estate.commercialProperties',
                                              ).toLowerCase(),
                                              location: translatedLocation,
                                          }),
                                          'partial',
                                      ),
                                      url: buildSearchUrl({
                                          lang,
                                          transaction: TRANSACTION.sell,
                                          estate: ESTATE.commercialProperty,
                                          location: property.location,
                                          market: 'PRIMARY',
                                      }),
                                  },
                              ]
                            : []),
                    ],
                    cityLandingLocation: property.cityLandingLocation,
                };
            }),
        [getTranslatedLocation, lang, primaryMarketSection?.availableSubSection, propertiesFromPrimaryMarketSection, t],
    );

    return {
        propertiesForRent,
        propertiesForSell,
        propertiesFromPrimaryMarket,
    };
};
/* eslint-enable no-restricted-syntax */

import { SITE_CONFIG } from '@config/siteConfig';
import { NexusPromotedOffer } from '@domains/home/components/NexusPromotedOffersSection/components/NexusPromotedOffer/NexusPromotedOffer';
import {
    LinkLabel,
    SliderWrapper,
    StyledNexusSectionWrapper,
} from '@domains/home/components/NexusPromotedOffersSection/NexusPromotedOffersSection.theme';
import { LocalLinkContainer } from '@domains/home/HomePage.theme';
import type { HomepageAd } from '@domains/home/types/homepageAd';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC, JSX } from 'react';
import { useCallback, useRef } from 'react';

interface Props {
    promotedOffers: HomepageAd[];
}
const NexusPromotedOffersSection: FC<Props> = ({ promotedOffers }) => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const nexusPromotedOffersSectionRef = useRef<HTMLDivElement | null>(null);
    const atlasRedirects = SITE_CONFIG.atlasRedirects;
    const trackOnMoreOfferClick = useCallback(() => trackEvent('see_all_promoted'), [trackEvent]);

    const onMoreOfferClick = (): void => {
        trackOnMoreOfferClick();
    };

    useTrackImpression(nexusPromotedOffersSectionRef, () => {
        trackEvent('seo_links_impression', { touch_point_button: 'promoted_ads' });
    });

    return (
        <StyledNexusSectionWrapper
            headline={t('frontend.homepage.offers-section.header')}
            link={{
                label: t('frontend.homepage.offers-section.more-offers-link'),
                onClick: onMoreOfferClick,
                href: atlasRedirects.offers,
            }}
            ref={nexusPromotedOffersSectionRef}
            isScrollableHorizontally
        >
            <SliderWrapper data-testid="offers-wrapper" data-cy="promoted-offers-slider">
                <Slider
                    items={promotedOffers}
                    renderItem={(promotedOffer): JSX.Element => (
                        <NexusPromotedOffer key={promotedOffer.id} promotedOffer={promotedOffer} />
                    )}
                    buttonTopPosition={-60}
                    trackingTouchPointButton="promoted_ads"
                    shouldUseEdgePaddings
                />
                <LocalLinkContainer>
                    <LocalLink href={atlasRedirects.offers} passHref>
                        <a onClick={onMoreOfferClick} data-cy="redirect-to-offers">
                            <LinkLabel variant="link">
                                {t('frontend.homepage.offers-section.more-offers-link')}
                            </LinkLabel>
                        </a>
                    </LocalLink>
                </LocalLinkContainer>
            </SliderWrapper>
        </StyledNexusSectionWrapper>
    );
};

export default NexusPromotedOffersSection;

import type { NexusUsabilitySectionData } from '@config/home/homeConfig';
import { NexusHomepageTabs } from '@domains/home/components/NexusHomepageTabs/NexusHomepageTabs';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { type JSX, useMemo, useRef } from 'react';

import { SubHeadline } from './NexusUsabilitySection.theme';
import { Tile } from './Tile';

interface Props {
    usabilityData: NexusUsabilitySectionData;
}

export const NexusUsabilitySection = ({ usabilityData }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const nexusUsabilitySectionRef = useRef<HTMLDivElement | null>(null);

    useTrackImpression(nexusUsabilitySectionRef, () => {
        trackEvent('seo_links_impression', { touch_point_button: 'why_section' });
    });

    const tabs = useMemo(
        () => [
            {
                name: t('frontend.homepage.nexus-usability-section.tab-for-seekers'),
                touchPointButton: 'why_for_seekers',
            },
            {
                name: t('frontend.homepage.nexus-usability-section.tab-for-professionals'),
                touchPointButton: 'why_for_professionals',
            },
        ],
        [t],
    );

    return (
        <NexusSectionWrapper
            headline={t('frontend.homepage.nexus-usability-section.headline')}
            ref={nexusUsabilitySectionRef}
            isScrollableHorizontally
        >
            <NexusHomepageTabs tabs={tabs}>
                <>
                    <SubHeadline>{t('frontend.homepage.nexus-usability-section.subheadline')}</SubHeadline>
                    <Slider
                        items={usabilityData.forSeekers}
                        arrowsVisibility="none"
                        trackingTouchPointButton="why_section"
                        shouldUseEdgePaddings
                        renderItem={(tileData): JSX.Element => <Tile {...tileData} />}
                    />
                </>
                <>
                    <SubHeadline>{t('frontend.homepage.nexus-usability-section.subheadline')}</SubHeadline>
                    <Slider
                        items={usabilityData.forProfessionals}
                        arrowsVisibility="none"
                        trackingTouchPointButton="for-sell-section"
                        shouldUseEdgePaddings
                        renderItem={(tileData): JSX.Element => <Tile {...tileData} />}
                    />
                </>
            </NexusHomepageTabs>
        </NexusSectionWrapper>
    );
};

import { ROUTE_SEGMENT } from '@config/routes';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTracking } from '@lib/tracking/useTracking';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import ChevronUp from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronUp';
import { P2 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useState } from 'react';

import {
    AccordionButton,
    ContentWrapper,
    Image,
    LinksContainer,
    ListItem,
    Tile,
    TILE_WIDTH,
} from './PopularLocationSliderTile.theme';

interface Props {
    id: string;
    title: string;
    image: string;
    cityLandingLocation?: string;
    links: {
        estateLabel?: string;
        label: string;
        title: string;
        url: string;
    }[];
}

const { staticAssetsPrefix } = getPublicEnvConfig();

export const PopularLocationSliderTile = ({ title, image, links, cityLandingLocation }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const [isLinksVisible, setIsLinksVisible] = useState(false);
    const changeLinksVisibility = (): void => {
        setIsLinksVisible(!isLinksVisible);
    };

    const handleClick = (touchPointButton: string): void => {
        trackEvent('seo_link_click', { touch_point_button: touchPointButton });
    };

    return (
        <Tile title={title}>
            {cityLandingLocation ? (
                <LocalLink passHref href={`/[lang]/${ROUTE_SEGMENT.cityLanding}/${cityLandingLocation}`}>
                    <a onClick={() => handleClick('location_landing_entry_point')}>
                        <Image src={`${staticAssetsPrefix}${image}`} alt={title} width={TILE_WIDTH} height="166" />
                    </a>
                </LocalLink>
            ) : (
                <Image src={`${staticAssetsPrefix}${image}`} alt={title} width={TILE_WIDTH} height="166" />
            )}
            <ContentWrapper isActive={isLinksVisible} linksLength={links.length}>
                <AccordionButton
                    variant="flat"
                    suffixIcon={isLinksVisible ? ChevronDown : ChevronUp}
                    onClick={changeLinksVisibility}
                    isActive={isLinksVisible}
                >
                    {title}
                </AccordionButton>
                <LinksContainer isActive={isLinksVisible}>
                    {links.map((link) => {
                        return (
                            <ListItem key={link.url}>
                                <LocalLink href={link.url} passHref>
                                    <a onClick={() => handleClick('seo_links')}>
                                        <P2 variant="link">{link.estateLabel}</P2>
                                    </a>
                                </LocalLink>
                            </ListItem>
                        );
                    })}
                </LinksContainer>
            </ContentWrapper>
        </Tile>
    );
};

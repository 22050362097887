import type { BreadcrumbSchemaMarkup } from '@config/schemaMarkup/type';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';

export const generateBreadcrumbSchemaMarkup = (
    breadcrumbItems?: Breadcrumb[] | null,
    siteURL?: string,
): BreadcrumbSchemaMarkup | undefined => {
    if (!breadcrumbItems || !siteURL) {
        return;
    }

    return {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItems.map(({ url, label }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': `${siteURL}${url}`,
                '@type': 'WebPage',
                url: `${siteURL}${url}`,
                name: label,
            },
        })),
    };
};

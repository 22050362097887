import type { NexusUsabilitySectionItem } from '@config/home/type';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { Description, Headline, StyledImage, StyledLinkButton, TextWrapper, TileWrapper } from './Tile.theme';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const Tile = ({ headline, description, link, image }: NexusUsabilitySectionItem): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleClick = (): void => {
        trackEvent(link.trackingEventName, { touch_point_button: link.touchPointButton });
    };

    return (
        <TileWrapper>
            <StyledImage src={`${staticAssetsPrefix}${image}`} loading="lazy" alt={t(headline)} />
            <TextWrapper>
                <Headline variant="bold">{t(headline)}</Headline>
                <Description>{t(description)}</Description>
                <LocalLink passHref href={link.href}>
                    <StyledLinkButton variant="link" onClick={handleClick}>
                        {t(link.label)}
                    </StyledLinkButton>
                </LocalLink>
            </TextWrapper>
        </TileWrapper>
    );
};

import type { FaqSchemaItemData, GraphSchemasArray, SchemaMarkupData } from '@config/schemaMarkup/type';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { generateProductSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateProductSchemaMarkup';
import type { SearchActionSchemaProps } from '@domains/shared/helpers/schemaMarkup/generateSearchActionSchemaMarkup';
import { generateSearchActionSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateSearchActionSchemaMarkup';
import type { WebPageSchemaProps } from '@domains/shared/helpers/schemaMarkup/generateWebPageSchemaMarkup';
import { generateWebPageSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateWebPageSchemaMarkup';
import type { Transaction } from '@type/search/filters/transaction';

import { generateFaqPageSchemaMarkup } from './generateFaqPageSchemaMarkup';

export interface GenerateSchemaMarkupProps {
    pageSchemaData: WebPageSchemaProps;
    searchActionSchemaData?: SearchActionSchemaProps;
    productSchemaData?: {
        urlPath?: string;
        businessFunction: Transaction;
        items: AdvertListItem[];
    };
    breadcrumbSchemaData?: Breadcrumb[] | null;
    faqSchemaData?: FaqSchemaItemData[];
}

export const generateSchemaMarkup = ({
    pageSchemaData,
    searchActionSchemaData,
    productSchemaData,
    breadcrumbSchemaData: breadcrumbItems,
    faqSchemaData,
}: GenerateSchemaMarkupProps): SchemaMarkupData | null => {
    const { name, description, urlPath, lang, primaryImageOfPage, headline, isPartOfDescription } = pageSchemaData;
    const webPageSchema = generateWebPageSchemaMarkup({
        name,
        description,
        lang,
        urlPath,
        primaryImageOfPage,
        breadcrumbItems,
        headline,
        isPartOfDescription,
    });

    if (!webPageSchema) {
        return null;
    }

    const graphSchemasArray: GraphSchemasArray = [webPageSchema];

    if (searchActionSchemaData) {
        const searchActionSchema = generateSearchActionSchemaMarkup({
            urlPath: searchActionSchemaData.urlPath,
            lang: searchActionSchemaData.lang,
            potentialAction: searchActionSchemaData.potentialAction,
        });

        if (searchActionSchema) {
            graphSchemasArray.push(searchActionSchema);
        }
    }
    const adsWithPrice = productSchemaData?.items.filter((adData) => adData.totalPrice !== null);

    if (productSchemaData && adsWithPrice && adsWithPrice.length > 0) {
        const productSchema = generateProductSchemaMarkup({
            name,
            businessFunction: productSchemaData.businessFunction,
            description,
            items: adsWithPrice,
            imageUrl: primaryImageOfPage?.url,
            lang,
            urlPath: productSchemaData.urlPath,
        });

        if (productSchema) {
            graphSchemasArray.push(productSchema);
        }
    }

    if (faqSchemaData) {
        const faqSchema = generateFaqPageSchemaMarkup(faqSchemaData);

        if (faqSchema) graphSchemasArray.push(faqSchema);
    }

    return {
        '@context': 'https://schema.org',
        '@graph': graphSchemasArray,
    };
};

import { NexusHomepageTabs } from '@domains/home/components/NexusHomepageTabs/NexusHomepageTabs';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { useGetPropertiesForSellAndForRent } from '@domains/home/hooks/useGetPropertiesForSellAndForRent';
import { RealEstateSliderTile } from '@domains/shared/components/RealEstateSliderTile/RealEstateSliderTile';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { type FC, type JSX, type PropsWithChildren, useMemo, useRef } from 'react';

export const NexusPropertiesForSellAndRentSection: FC<PropsWithChildren> = () => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { propertiesForSell, propertiesForRent } = useGetPropertiesForSellAndForRent();
    const nexusPropertiesForSellAndRentSectionRef = useRef<HTMLDivElement | null>(null);

    useTrackImpression(nexusPropertiesForSellAndRentSectionRef, () => {
        trackEvent('seo_links_impression', { touch_point_button: 'categories_tiles' });
    });

    const tabs = useMemo(
        () => [
            {
                name: t('frontend.homepage.props-for-sell-section.for-sale-label'),
                touchPointButton: 'category_sale',
            },
            {
                name: t('frontend.homepage.props-for-sell-section.for-rent-label'),
                touchPointButton: 'category_rent',
            },
        ],
        [t],
    );

    const handleClick = (categoryName: 'sale' | 'rent'): void => {
        trackEvent('seo_link_click', {
            touch_point_button: 'categories_tiles',
            cat_l1_id: categoryName,
            cat_l1_name: categoryName,
        });
    };

    return (
        <NexusSectionWrapper
            headline={t('frontend.homepage.nexus-properties-for-sell-and-rent-section.headline')}
            ref={nexusPropertiesForSellAndRentSectionRef}
            data-cy="properties-for-sell-and-rent-section"
            isScrollableHorizontally
        >
            <NexusHomepageTabs tabs={tabs}>
                <Slider
                    items={propertiesForSell}
                    buttonTopPosition={-68}
                    trackingTouchPointButton="for-sell-section"
                    shouldUseEdgePaddings
                    renderItem={({ id, label, title, url, image }): JSX.Element => (
                        <RealEstateSliderTile
                            id={id}
                            label={label}
                            title={title}
                            url={url}
                            image={image}
                            onClick={() => handleClick('sale')}
                            data-cy="for-sell-section-tile"
                        />
                    )}
                />
                <Slider
                    items={propertiesForRent}
                    buttonTopPosition={-68}
                    trackingTouchPointButton="for-rent-section"
                    shouldUseEdgePaddings
                    renderItem={({ id, label, title, url, image }): JSX.Element => (
                        <RealEstateSliderTile
                            id={id}
                            label={label}
                            title={title}
                            url={url}
                            image={image}
                            onClick={() => handleClick('rent')}
                            data-cy="for-rent-section-tile"
                        />
                    )}
                />
            </NexusHomepageTabs>
        </NexusSectionWrapper>
    );
};

const APPLE_DEVICES = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

/**
 * @param userAgent - window.navigator.userAgent
 * @param navigatorPlatform - window.navigator.platform
 * @returns Boolean accroding to device type
 */
const isAppleDevice = (userAgent: string, navigatorPlatform: string): boolean | undefined =>
    APPLE_DEVICES.includes(navigatorPlatform) || (userAgent.includes('Mac') && 'ontouchend' in document);

/**
 * @param userAgent - window.navigator.userAgent
 * @returns Boolean accroding to device type
 */
const isAndroidDevice = (userAgent: string): boolean | undefined => /(android)/i.test(userAgent);

interface MobileRecognitionFunctions {
    isAndroidDevice: (userAgent: string) => boolean | undefined;
    isAppleDevice: (userAgent: string, navigatorPlatform: string) => boolean | undefined;
}

export const useMobileRecognition = (): MobileRecognitionFunctions => {
    return {
        isAndroidDevice,
        isAppleDevice,
    };
};
